import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

const { info } = colors;
const { borderRadius } = borders;
const tabs = {
  styleOverrides: {
    root: {
      position: "relative",
      minHeight: "unset",
      borderRadius: borderRadius.xl
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important"
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%"
      }
    },

    indicator: {
      backgroundColor: info.main,
      transition: "all 500ms ease"
    }
  }
};

export default tabs;
