// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const svgIcon = {
  defaultProps: {
    fontSize: "inherit"
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: "inherit !important"
    },

    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`
    }
  }
};

export default svgIcon;
