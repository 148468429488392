import { types } from "utils/types/types";
import { setItemStorareCryp } from "utils/functions/sessionStorageCrypto";

export const authReduce = (state = {}, action) => {
  switch (action.type) {
    case types.login:
      setItemStorareCryp("user", action.payload.token, "USERDATASESSION");
      return {
        isAuthenticated: true,
        accessToken: action.payload.token,
        rol: action.payload.rol,
        idLead: action.payload.idLead,
        sellerNombre: action.payload.sellerNombre,
        sellerApellido: action.payload.sellerApellido,
        email: action.payload.email,
        events: action.payload.events,
        creditsLength: action.payload.creditsLength,
        backendVersion: action.payload.backendVersion,
        expiresInMs: action.payload.expiresInMs
      };
    case types.logout:
      sessionStorage.clear();
      return {
        isAuthenticated: false,
        user: null,
        rol: null,
        idLead: null,
        sellerNombre: null,
        sellerApellido: null,
        email: null,
        creditsLength: null,
        backendVersion: null,
        expiresInMs: null
      };
    case types.setAuthInfo:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
