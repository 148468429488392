import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

const { transparent, info, grey } = colors;
const { borderRadius } = borders;

const mobileStepper = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      borderRadius: borderRadius.lg,

      "&.MuiMobileStepper-root": {
        backgroundColor: transparent.main
      },
      "& .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot": {
        backgroundColor: grey[400]
      },
      "& .MuiMobileStepper-dot": {
        backgroundColor: info.main
      }
    }
  }
};

export default mobileStepper;
