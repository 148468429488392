import { AES, enc } from "crypto-js";

const setItemStorareCryp = (nameItem, objectItem, keyItem) => {
  return sessionStorage.setItem(
    nameItem,
    AES.encrypt(JSON.stringify(objectItem), keyItem)
  );
};

const getItemStorageDecryp = (nameItem, keyItem) => {
  return JSON.parse(
    AES.decrypt(sessionStorage.getItem(nameItem), keyItem).toString(enc.Utf8)
  );
};

export { setItemStorareCryp, getItemStorageDecryp };
