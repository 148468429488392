import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

const { transparent } = colors;
const { borderRadius } = borders;

const stepper = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      borderRadius: borderRadius.lg,

      "&.MuiPaper-root": {
        backgroundColor: transparent.main
      }
    }
  }
};

export default stepper;
