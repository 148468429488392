import React, { Suspense, lazy, useEffect, useReducer } from "react";
import * as Sentry from "@sentry/react";
import AuthContext from "context/AuthProvider";
import { authReduce } from "auth/authReduce";
import { setItemStorareCryp } from "utils/functions/sessionStorageCrypto";
import { getItemStorageDecryp } from "utils/functions/sessionStorageCrypto";
import { checkPerformActionByRole, performActionRoles } from "utils/consts";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./assets/theme";
import { clientReduce } from "reduce/clientReduce";
import ClientContext from "context/ClientContext";
import "./index.css";

const Router = lazy(() => import("routes/routes"));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      if (
        checkPerformActionByRole(performActionRoles.IT, this.props.user.email)
      ) {
        return (
          <div style={{ marginLeft: "2rem" }}>
            <p>Ha ocurrido un error:</p>
            <p>{this.state.error.toString()}</p>
          </div>
        );
      } else {
        window.location.reload();
      }
    }
    return this.props.children;
  }
}

const init = () => {
  return sessionStorage.getItem("user") != null
    ? getItemStorageDecryp("user", "USERDATASESSION")
    : { logged: false };
};

const initClient = () => {
  return sessionStorage.getItem("lead") != null
    ? getItemStorageDecryp("lead", "LEADDATASESSION")
    : { logged: false };
};

export default function App() {
  const [user, dispatch] = useReducer(authReduce, {}, init);
  const [lead, dispatchLead] = useReducer(clientReduce, {}, initClient);

  useEffect(() => {
    if (sessionStorage.getItem("user") !== "")
      setItemStorareCryp("user", user, "USERDATASESSION");
  }, [user]);

  useEffect(() => {
    if (sessionStorage.getItem("lead") !== "")
      setItemStorareCryp("lead", lead, "LEADDATASESSION");
  }, [lead]);

  document.addEventListener("wheel", () => {
    const activeElement = document.activeElement;
    if (activeElement.type === "number") {
      activeElement.blur();
    }
  });

  return (
    <ErrorBoundary user={user}>
      <AuthContext.Provider value={{ user, dispatch }}>
        <ClientContext.Provider value={{ lead, dispatchLead }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={null}>
              <Router />
            </Suspense>
          </ThemeProvider>
        </ClientContext.Provider>
      </AuthContext.Provider>
    </ErrorBoundary>
  );
}
